import axios from 'axios';

export default class AuthService {
    resetPasswordRequest(data) {
        return axios.post('api/v1/auth/password/reset/request', data);
    }

    resetPasswordValidateToken(data) {
        return axios.post('api/v1/auth/password/reset/validate', data);
    }

    resetPasswordProcess(data) {
        return axios.post('api/v1/auth/password/reset/process', data);
    }

    emailAccept(token) {
        return axios.post('api/v1/invitations/accept', { token: token });
    }
}
