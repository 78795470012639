<template>
    <div class="email-accept-container">
        <h1>Email Confirmation</h1>
        <p>Thank you for confirming your email. Please change your password</p>
    </div>
</template>
<script>
    import ApiService from '@/services/api';

    export default {
        data() {
            return {
                token: null,
            };
        },
        created() {
            this.token = this.$route.params.token;
            this.acceptUser();
        },
        methods: {
            async acceptUser() {
                try {
                    const apiService = this.$store.getters['user/apiService'];

                    await apiService.attemptEmailAccept(this.token);
                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.record.save.success.title'),
                        message: this.$t('notification.record.save.success.message'),
                    });
                    this.$router.push({ path: '/settings/account' });
                } catch ({ response }) {
                    console.warn(response);
                    this.$router.push({ path: '/' });
                    // this.$refs.form.setErrors(response.data.info);
                }
            },
        },
    };
</script>
